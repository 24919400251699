import React from 'react';
import Bg from '../../images/shapes/shape_title_under_line.svg';
import { Link as ScrollLink } from 'react-scroll';

const Hero2 = (props) => {
  return (
    <section className='software_company_hero_section xb-hidden'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-12'>
            <div className='content_wrap'>
              <div
                className='heading_focus_text has_underline text-white d-inline-flex'
                style={{ backgroundImage: `url(${Bg})` }}
              >
                Soluções sob medida
              </div>
              <h1 className='text-white'>
                Apoiamos empresas na <mark>transformação digital</mark> de seus
                negócios.
              </h1>
              <p>
                Ajudamos sua empresa com soluções tecnológicas, permitindo que
                você foque no que realmente importa, o crescimento do seu
                negócio e a satisfação dos seus clientes.
              </p>
              <ul className='step_list text-white unordered_list_block'>
                <li>Solução adequada à necessidade do cliente</li>
                <li>Qualidade e Respeito aos Prazos</li>
              </ul>
              <ul className='btns_group unordered_list p-0 justify-content-start'>
                <li>
                  <ScrollLink
                    className='btn'
                    to='contact'
                    style={{ cursor: 'pointer' }}
                  >
                    <span className='btn_label' data-text='Vamos começar!'>
                      Nos contate agora!
                    </span>
                    <span className='btn_icon'>
                      <i className='fa-solid fa-arrow-up-right'></i>
                    </span>
                  </ScrollLink>
                </li>
                <li>
                  <a className='hotline_block' href='tel:+420318568511'>
                    <span className='hotline_icon'>
                      <i className='fa-solid fa-phone-volume'></i>
                    </span>
                    <span className='hotline_content'>
                      <small>LIGUE PARA NÓS</small>
                      <strong className='text-white'>(+420) 318 568 511</strong>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero2;
