import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import logo from '../../images/site_logo/partnercode-logo-white.png'
import logo2 from '../../images/site_logo/partnercode-logo-blue.png'
import MobileMenu from '../MobileMenu/MobileMenu'
import { Link as ScrollLink } from 'react-scroll';

const Header2 = (props) => {

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
      <header className='site_header site_header_2'>
        <div
          className={`header_bottom stricky  ${
            isSticky ? 'stricked-menu stricky-fixed' : ''
          }`}
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xl-3 col-lg-2 col-5'>
                <div className='site_logo'>
                  <Link onClick={ClickHandler} className='site_link' to='/'>
                    <img
                      src={logo}
                      alt='Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template'
                    />
                    <img
                      src={logo2}
                      alt='Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template'
                    />
                  </Link>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-5 offset-xl-6 offset-lg-6 offset-2'>
                <ul className='header_btns_group unordered_list justify-content-end'>
                  <li>
                    <ScrollLink
                      className='btn btn-primary'
                      to='contact'
                      style={{ cursor: 'pointer' }}
                    >
                      <span className='btn_label' data-text='Vamos começar!'>
                        Entre em contato
                      </span>
                      <span className='btn_icon'>
                        <i className='fa-solid fa-arrow-up-right'></i>
                      </span>
                    </ScrollLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='mobail-wrap'>
            <div className={`mobail-menu ${mobailActive ? 'active' : ''}`}>
              <div className='xb-header-menu-scroll'>
                <div
                  className='xb-menu-close xb-hide-xl xb-close'
                  onClick={() => setMobailState(!mobailActive)}
                ></div>
                <nav className='xb-header-nav'>
                  <MobileMenu />
                </nav>
              </div>
            </div>
            <div
              className='xb-header-menu-backdrop'
              onClick={() => setMobailState(false)}
            ></div>
          </div>
        </div>
      </header>
    );
}

export default Header2;